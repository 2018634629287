// import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Hero from "./pages/Hero";
import WhatWeDo from "./pages/WhatWeDo";
import About from "./pages/About";
import Testimonials from "./pages/Testimonials";
import GetInTouch from "./pages/GetInTouch";
import { Route, Routes } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import React,{useEffect} from "react";
import "./fonts/stylesheet.css"
import FinancialServices from "./solutions/FinancialServices";
import Retail from "./solutions/Retail";
import ProjectAccounting from "./solutions/ProjectAccounting";
import Manufacturing from "./solutions/manufacturing";
import NonProfitOrganisation from "./solutions/Non-ProfitOrganizations";
import WholeSaleDistribution from "./solutions/WholesaleDistribution";
import FoodAndBeverage from "./solutions/FoodAndBeverage";


function App() {

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      {/* First page */}
      <div className="" style={{backgroundColor:'#eaeaea'}}>
        <div className="">
          <Header/>
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="what-we-do" element={<WhatWeDo />} />
            <Route path="about" element={<About />} />
            <Route path="testimonial" element={<Testimonials />} />
            <Route path="get-in-touch" element={<GetInTouch />} />
            <Route path="financial-services" element={<FinancialServices />} />
            <Route path="retail" element={<Retail />} />
            <Route path="manufacturing" element={<Manufacturing />} />
            <Route path="project-accounting" element={<ProjectAccounting />} />
            <Route path="nonProfitOrganisation" element={<NonProfitOrganisation />} />
            <Route path="wholeSaleDistribution" element={<WholeSaleDistribution />} />
            <Route path="foodAndBeverage" element={<FoodAndBeverage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
