import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/system";
import Slider from "react-slick";
import { NoEncryption } from "@mui/icons-material";

const logos = [
  { path: require("../assets/logo/nearpod.png") },
  { path: require("../assets/logo/bumble.png") },
  { path: require("../assets/logo/forbes.png") },
  { path: require("../assets/logo/bench.png") },
  { path: require("../assets/logo/nearpod.png") },
  { path: require("../assets/logo/bumble.png") },
  { path: require("../assets/logo/forbes.png") },
  { path: require("../assets/logo/bench.png") },
  { path: require("../assets/logo/nearpod.png") },
  { path: require("../assets/logo/bumble.png") },
  { path: require("../assets/logo/forbes.png") },
  { path: require("../assets/logo/bench.png") },
];
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: "linear",
  arrows: false,
};

const Corousel = () => {
  return (
    <>
      <Slider {...settings}>
        {logos.map((item, index) => (
          <Box
            component="img"
            sx={{
              height: 200,
              width: 250,
              maxHeight: { xs: 300, md: 300 },
              maxWidth: { xs: 300, md: 300 },
              objectFit: "contain",
            }}
            src={item.path}
            alt={index}
          />
        ))}
      </Slider>
    </>
  );
};

export default Corousel;
