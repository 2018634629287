import React, { useEffect, useState } from "react";
import group from "../assets/home/Group.png";
import nearpod from "../assets/logo/nearpod.png";
import bumble from "../assets/logo/bumble.png";
import forbes from "../assets/logo/forbes.png";
import bench from "../assets/logo/bench.png";
import Biography from "../components/home/Biography";
import Industries from "../components/home/Industries";
import OurPartner from "../components/home/OurPartner";
import OurTeam from "../components/home/OurTeam";
import Services from "./services/Services";
import upIcon from "../assets/logo/upIcon.png";
import rightArrow from "../assets/logo/arrowright.png";
import Solutions from "../assets/solutions.jpg";
import WhatWeKnownFor from "../components/WhatWeKnownFor";
import Image_One from "../assets/1.png";
import Image_Two from "../assets/2.png";
import Image_Three from "../assets/3.png";
import Image_Four from "../assets/4.png";
import Image_Five from "../assets/5.png";
import Laptop from "../assets/contract_mng.png";
import "../fonts/stylesheet.css";
import "./hero.css";
import Corousel from "./Corousel";
import Count from "../components/count/Count";
import ClientCount from "../components/count/ClientCount";
import { MenuItem } from "@mui/material";
import ContactUsModal from "../components/ContactUsModal";
import Demo from "../assets/whatWeDo/demo.png";
import Team from "../components/count/Team";
import { Link } from "react-router-dom";

// import VisitorCounter from "../components/visitor/VisitorCounter";

const images = [Image_One, Image_Two, Image_Three, Image_Four, Image_Five];

const Hero = () => {
  const [scrollToTop, setScrollToTop] = useState(false);
  const [showModal, setshowModal] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === images.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 7000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //   const coursesPage = () => {
  //     history.push("about")
  // }

  return (
    <div style={{ backgroundColor: "#eaeaea" }}>
      <div
        style={{
          backgroundImage:
            "radial-gradient(50.07% 50.07% at 33.75% 49.93%, #344B87 0%, #9ED2C4 100%)",
        }}
      >
        <div className="container">
          <ContactUsModal
            show={showModal}
            handleClose={() => setshowModal(false)}
          />

          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h1
                className="text-light mt-5 page-title"
                style={{
                  fontFamily: "Gogh Extra",
                  fontSize: "3.2em",
                  // fontWeight: "400",
                  lineHeight: "3.3rem",
                }}
                data-aos="fade-up"
                data-aos-duration="2500"
                // data-aos-anchor-placement="center-center"
              >
                Power up your customer <br /> support with Suite
                <span style={{ fontFamily: "Louis George Cafe" }}>Cast</span>
              </h1>
              <p
                className="text-light w-75 p-text m-w-100"
                data-aos="fade-up"
                data-aos-duration="2500"
                style={{ fontFamily: "Louis George Cafe", fontSize: "1.4rem" }}
              >
                Accelerate your Oracle NetSuite implementation by leveraging
                industry-leading best practices along with the most up-to-date
                approaches to implementing Oracle NetSuite. We accomplish this
                by applying a custom methodology for each industry, which can
                help your company go live in record time.
              </p>
              <button
                className="border border-0 text-light p-2 mt-4 mb-4 m-w-100 btn-mobile"
                style={{
                  backgroundColor: "#344B87",
                  fontSize: "1.4rem",
                  width: "30%",
                  fontFamily: "Louis George Cafe",
                }}
              >
                <Link
                  style={{
                    color: "#ffffff",
                    textDecoration: "none",
                  }}
                  to="get-in-touch"
                >
                  Get in touch
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="dt-laptop-slider">
                {/* <div className="laptop-screen"> */}
                <img
                  className="laptop-screen"
                  src={Laptop}
                  alt="laptop screen"
                />
                <img
                  className="app-shot"
                  src={images[currentIndex]}
                  // style={{ width: "56%", height: "57.5%" }}
                />
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className=""
        style={{ backgroundColor: "#344B87" }}
        // data-aos="zoom-in"
        // data-aos-duration="1500"
      >
        <div className="container">
          {/* <div>
          <img className="img-fluid " src={nearpod} alt="nearpod img" />
        </div>
        <div>
          <img className="img-fluid " src={bench} alt="bench img" />
        </div>
        <div>
          <img className="img-fluid" src={forbes} alt="forbes img" />
        </div>
        <div>
          <img className="img-fluid " src={bumble} alt="bumble img" />
        </div> */}
          {/* <Slider {...settings}>
          {logos.map((item, index) => (
            <Box
              component="img"
              sx={{
                height: 233,
                width: 350,
                maxHeight: { xs: 120, md: 167 },
                maxWidth: { xs: 120, md: 250 },
                objectFit: "contain",
              }}
              src={item.path}
              alt={index}
            />
          ))}
        </Slider> */}
          <Corousel />
        </div>
      </div>
      {/* //Accordion */}
      {/* <Biography /> */}
      <div className="container p-4 d-flex flex-column justify-content-center align-items-center">
        <img
          onClick={goToTop}
          src={upIcon}
          alt="UpIcon img"
          data-aos="fade-up"
          data-aos-duration="1000"
        />
        <p
          className="mt-1 mb-0"
          style={{ color: "#9ED2C4", fontWeight: 700, fontSize: "1.5rem" }}
          data-aos="zoom-out"
        >
          About
        </p>
        <h2
          className="page-title"
          style={{
            color: "#344B87",
            fontWeight: "700",
            fontFamily: "Gogh Extra",
            fontSize: "3.2rem",
          }}
          data-aos="zoom-out"
        >
          Who we are?
        </h2>
        <p
          className="text-center pt-2 p-text"
          style={{
            color: "#111111",
            fontWeight: "400",
            fontSize: "1.4rem",
            lineHeight: "1.7rem",
            fontFamily: "Louis George Cafe",
          }}
          data-aos="fade-up"
          data-aos-duration="2500"
        >
          SUITECast is a digital management consulting firm with a footprint
          that offers advisory, implementation, and managed services to some of
          the world’s most innovative and recognizable companies. SUITECast is
          headquartered out of Pune, India. SUITECast’s certified professionals
          come from industry backgrounds and are armed with perspective for
          every engagement.
        </p>
        <p
          className="text-center"
          style={{
            color: "rgba(17, 17, 17, 0.5)",
            fontWeight: "400",
            fontSize: "1.2rem",
            lineHeight: "1.3rem",
          }}
        >
          <Link
            style={{
              color: "#ffffff",
              textDecoration: "none",
              color: "rgba(17, 17, 17, 0.5)",
              fontWeight: "400",
              fontSize: "1.2rem",
              lineHeight: "1.3rem",
            }}
            to="what-we-do"
          >
            know more about us <img src={rightArrow} alt="rightArrow" />
          </Link>
        </p>
      </div>
      {/* <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="text-center">
              <Count />
            </div>
          </div>

          <div className="col-md-5">
            <div className="text-center">
              <ClientCount />
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="d-flex flex-column p-3"
        style={{ backgroundColor: "rgba(158, 210, 196, 0.5)" }}
      >
        <h1
          className="text-center page-title"
          style={{
            color: "#344B87",
            fontSize: "3.2rem",
            fontWeight: "700",
            fontFamily: "Gogh Extra",
          }}
        >
          Don’t just take our word for it!
        </h1>
        <div className="d-lg-flex d-md-flex justify-content-around">
          <div className="d-flex flex-column justify-content-center align-items-center">
            {/* <p className='p-0' style={{ color: '#344B87', fontSize: '5rem', fontWeight: '800' }}>106+</p> */}
            <Count />
            <p
              className="p-0"
              style={{
                color: "#344B87",
                fontSize: "16px",
                fontWeight: 500,
                marginTop: "-25px",
                fontFamily: "Gogh Extra",
              }}
            >
              Projects
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {/* <p className='' style={{ color: '#344B87', fontSize: '5rem', fontWeight: '800' }}>30+</p> */}
            <ClientCount />
            <p
              className="p-0"
              style={{
                color: "#344B87",
                fontSize: "16px",
                fontWeight: 500,
                marginTop: "-25px",
                fontFamily: "Gogh Extra",
              }}
            >
              Clients
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {/* <p className='' style={{ color: '#344B87', fontSize: '5rem', fontWeight: '800' }}>10+</p> */}
            <Team />
            <p
              className="p-0"
              style={{
                color: "#344B87",
                fontSize: "16px",
                fontWeight: 500,
                marginTop: "-25px",
                fontFamily: "Gogh Extra",
              }}
            >
              Expert Team
            </p>
          </div>
        </div>
      </div>

      <WhatWeKnownFor />
      <Industries />

      <div className="container mt-md-5 mb-md-5">
        <div className="row">
          <div className="col-md-8">
            <Services />
          </div>
          <div className="col-md-4">
            <img className="pb-md-5 img-fluid" src={Solutions} alt="Solutions" />
          </div>
        </div>
      </div>
      <div className="container my-4 my-md-5">
        <div
          style={{
            backgroundImage: `url(${Demo})`,
            backgroundRepeat: "no-repeat",
          }}
          className="p-md-5 d-flex justify-content-center"
        >
          <MenuItem onClick={() => setshowModal(true)}>
            <div className="d-flex justify-content-center">
              <div className="d-flex justify-content-center">
                <button
                  style={{
                    backgroundColor: "white",
                    color: "#344B87",
                    fontWeight: "600",
                    fontSize: "1.2em",
                    border: 0,
                    borderRadius: 2,
                    fontFamily: "Gogh Extra",
                  }}
                  className="px-sm-5 py-3 mx-sm-5 "
                >
                  Request a Demo or More Information
                </button>
              </div>
            </div>
          </MenuItem>
        </div>
      </div>
      {/* <OurPartner />
      <OurTeam /> */}
    </div>
  );
};

export default Hero;
