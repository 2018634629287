import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Box,
  Button,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";

const GetInTouch = () => {
  return (
    <>
      <div
      className="our-team"
        style={{
          // backgroundColor: "rgb(52,75,135)",
          background:
            "radial-gradient(circle, rgba(158,210,196,1) 0%, rgba(52,75,135,1) 76%)",
          paddingTop: "10em",
          paddingBottom: "10rem",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "white",
              fontWeight: 700,
              fontSize: "1.4rem",
              marginLeft: "6rem",
              fontFamily: "Louis George Cafe",
            }}
          >
            Let’s Connect
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontWeight: 500,
              fontSize: "4rem",
              marginLeft: "6rem",
              fontFamily: "Gogh Extra",
            }}
          >
            How can we help you?
          </Typography>
        </Box>
      </div>
      <div className="container touch-form">
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: "white",
            marginTop: "-4rem",
            marginBottom: "5rem",
            fontFamily: "Gogh Extra"
          }}
        >
          <Grid item xl={5} lg={5} md={5} xs={12} sx={{ margin: "2rem" }}>
            <TextField
              label="Name"
              variant="outlined"
              placeholder="Firstname"
              fullWidth
              // size='small'
              type="text"
              sx={{
                borderColor: "#344B87",
                "& fieldset": { border: "none" },
                backgroundColor: "#eaeaea",
                my: 2,
                fontFamily: "Gogh Extra"
              }}
            />
            <TextField
              label="Email"
              variant="outlined"
              placeholder="Email"
              fullWidth
              // size='small'
              type="email"
              sx={{
                borderColor: "#344B87",
                "& fieldset": { border: "none" },
                backgroundColor: "#eaeaea",
                my: 2,
                fontFamily: "Gogh Extra"
              }}
            />
            {/* <TextField
              label="Mobile Number"
              variant="outlined"
              placeholder="Mobile Number"
              fullWidth
              // size='small'
              type="number"
              sx={{
                borderColor: "#344B87",
                "& fieldset": { border: "none" },
                backgroundColor: "#eaeaea",
                my: 2,
              }}
            /> */}
            <TextField
              label="Phone Number"
              variant="outlined"
              placeholder="xxx-xxx-xxxx"
              fullWidth
              // size='small'
              type="text"
              sx={{
                borderColor: "#344B87",
                "& fieldset": { border: "none" },
                backgroundColor: "#eaeaea",
                my: 2,
                fontFamily: "Gogh Extra"
              }}
            />
            <TextField
              label="Organization"
              variant="outlined"
              placeholder="Organization"
              fullWidth
              // size='small'
              type="text"
              sx={{
                borderColor: "#344B87",
                "& fieldset": { border: "none" },
                backgroundColor: "#eaeaea",
                my: 2,
              }}
            />
            <TextareaAutosize
              placeholder="Message"
              minRows={7}
              style={{
                backgroundColor: "#eaeaea",
                width: "100%",
                borderColor: "gray",
                padding: 10,
                fontFamily: "inherit",
                fontSize: 16,
                border: "none",
                marginTop: "1rem",
                marginBottom: "0.5rem",
                fontFamily: "Gogh Extra"
              }}
            />
            <Button
              type="submit" 
              variant="contained"
              sx={{
                py: 2,
                px: 7,
                backgroundColor: "#344B87",
                boxShadow: 5,
                fontFamily: "Louis George Cafe",
                my: { xs: 5, md: 2, fontFamily: "Gogh Extra" },
              }}
            >
              Send
            </Button>
          </Grid>
          <Grid item xl={6} lg={6} md={6} xs={12}>
            <Typography
              sx={{
                fontWeight: 700,
                color: "Black",
                fontSize: "18px",
                marginTop: "3rem",
                fontFamily: "Louis George Cafe",
              }}
            >
              Email
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
              <MailOutlineIcon
                sx={{ color: "#344B87", fontSize: "2.5rem", marginRight: 2 }}
              ></MailOutlineIcon>
              <Typography
                sx={{ fontWeight: 500, color: "black", fontSize: "16px" }}
              >
                Email:
              </Typography>
              <Typography
                sx={{
                  color: "#344B87",
                  fontSize: "16px",
                  mx: 2,
                  fontWeight: 600,
                  fontFamily: "Gogh Extra",
                }}
              >
                sales@suitecast.cloud
              </Typography>
            </Box>
            <hr></hr>

            <Typography
              sx={{
                fontWeight: 700,
                color: "Black",
                fontSize: "18px",
                marginTop: "2rem",
                fontFamily: "Louis George Cafe",
              }}
            >
              India
            </Typography>

            <Box style={{ display: "flex", alignItems: "center" }}>
              <LocationOnIcon
                sx={{
                  color: "#344B87",
                  fontSize: "2.5rem",
                  marginRight: 2,
                  marginTop: "1rem",
                }}
              ></LocationOnIcon>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "black",
                  fontSize: "16px",
                  fontFamily: "Louis George Cafe",
                }}
              >
                Address:
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "#344B87",
                  fontSize: "16px",
                  marginTop: "0.3rem",
                  marginLeft: "1rem",
                  fontWeight: 600,
                  fontFamily: "Gogh Extra",
                }}
              >
                E-703, GK Rose E Mehar, Rahatani, Pune - 411017
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <PhoneIcon
                sx={{
                  color: "#344B87",
                  fontSize: "2.5rem",
                  marginRight: 2,
                  marginTop: "1rem",
                }}
              ></PhoneIcon>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "black",
                  fontSize: "16px",
                  fontFamily: "Louis George Cafe",
                }}
              >
                Phone:
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "#344B87",
                  marginTop: "0.3rem",
                  fontSize: "16px",
                  marginLeft: "1rem",
                  fontWeight: 600,
                  fontFamily: "Gogh Extra",
                }}
              >
                +91 85520 66655
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default GetInTouch;

{
  /* <div className="container">
<div className="pt-5 pb-2">
  <h1
    className="text-center"
    style={{ color: "#344B87", fontSize: "36px", fontWeight: "700" }}
  >
    Let’s get started
  </h1>
  <div className="container px-5">
    <div className="row p-3">
      <div className="col-lg-6">
        <div className="pb-4">
          <input
            className="w-100 p-2 rounded-0 border border-dark"
            type="text"
            placeholder="First name...."
          />
        </div>
        <div className="pb-4">
          <input
            className="w-100 p-2 rounded-0 border border-dark"
            type="text"
            placeholder="Last name...."
          />
        </div>
        <div className="pb-4">
          <input
            className="w-100 p-2 rounded-0 border border-dark"
            type="text"
            placeholder="Work e-mail...."
          />
        </div>
        <div className="pb-4">
          <input
            className="w-100 p-2 rounded-0 border border-dark"
            type="text"
            placeholder="Phone no...."
          />
        </div>
      </div>
      <div className="col-lg-6">
        {/* <input className='w-100 h-100 rounded-0 border border-dark' type="text" placeholder='How we can help you?' /> */
}
//         <textarea
//           className="form-control rounded-0 border border-dark text-secondary"
//           name=""
//           id=""
//           cols="30"
//           rows="10"
//         >
//           How we can help you?
//         </textarea>
//       </div>
//     </div>
//   </div>
//   <div className="d-flex justify-content-center pb-5">
//     <button
//       className="btn d-flex justify-content-center rounded-0 w-50 px-5 shadow-lg rounded"
//       style={{ backgroundColor: "#9ED2C4" }}
//     >
//       <span
//         style={{
//           color: "#344B87",
//           fontSize: "14px",
//           fontWeight: "600",
//         }}
//       >
//         Get in Touch
//       </span>
//     </button>
//   </div>
// </div>

{
  /* <div className="row">
  <h1
    className="text-center mb-5"
    style={{ color: "#344B87", fontSize: "36px", fontWeight: 700 }}
  >
    Contact us
  </h1>
  <div className="col-lg-6">
    <div className="d-lg-flex  justify-content-lg-center align-items-lg-center d-md-flex justify-content-md-center align-items-md-center">
      <img className="mb-5" src={Img} alt="Contact Image" />
    </div>
  </div>
  <div className="col-lg-6">
    <div className="d-flex p-2">
      <img
        className="img-fluid h-25"
        src={LocationImg}
        alt="Location img"
      />
      <p
        className="ms-3"
        style={{ color: "#000000", fontSize: "14px", fontWeight: 400 }}
      >
        kshya Nagar 1st Block 1st Cross, Rammurthy nagar,
        Bangalore-560016
      </p>
    </div>
    <div className="d-flex p-2">
      <img
        className="img-fluid h-25"
        src={CallImg}
        alt="Location img"
      />
      <p
        className="ms-3"
        style={{ color: "#000000", fontSize: "14px", fontWeight: 400 }}
      >
        +91 9876543210 <br></br> +91 8888887588
      </p>
    </div>
    <div className="d-flex p-2 align-items-center justify-content-start">
      <img className="img-fluid h-25" src={MsjImg} alt="Location img" />
      <p
        className="ms-3 mt-3"
        style={{ color: "#000000", fontSize: "14px", fontWeight: 400 }}
      >
        khelpdesk@suitecast.in
      </p>
    </div>
  </div>
</div>
</div> */
}
