import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logoSuite.png";
import Button from "@mui/material/Button";
// import logo from "../assets/logo/Logo.png";
// import "./header.css";

const Header = () => {
  const financial = useRef(null);
  const retail = useRef(null);
  const mfd = useRef(null);
  const wholesale = useRef(null);
  const professional = useRef(null);
  const nonProfit = useRef(null);
  const food = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div className="py-2" style={{ backgroundColor: "#9ed2c4" }}>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container d-flex flex-row">
          <div className="d-flex">
            <Link className="navbar-brand" to="/">
              <img src={logo} style={{ width: "50%" }} alt="Logo img" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="d-flex mobile-nav">
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link active dropdown-toggle"
                    to="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    aria-current="page"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      backgroundColor: "#344b87",
                      fontFamily: "Louis George Cafe",
                      borderRadius: 4,
                    }}
                  >
                    Solutions
                  </Link>
                  <ul className="dropdown-menu">
                    <li
                      className="container"
                      style={{
                        fontFamily: "Louis George Cafe",
                        fontSize: "1rem",
                        // fontWeight: "400",
                        lineHeight: "2rem",
                      }}
                    >
                      <Link
                        className="dropdown-item "
                        to="financial-services"
                        style={{
                          color: "#344b87",
                        }}
                      >
                        Financial Services
                      </Link>
                    </li>
                    <li
                      className="container"
                      style={{
                        fontFamily: "Louis George Cafe",
                        fontSize: "1rem",
                        // fontWeight: "400",
                        lineHeight: "2rem",
                      }}
                    >
                      <Link
                        className="dropdown-item "
                        to="retail"
                        style={{ color: "#344b87" }}
                      >
                        Retail
                      </Link>
                    </li>
                    <li
                      className="container"
                      style={{
                        fontFamily: "Louis George Cafe",
                        fontSize: "1rem",
                        // fontWeight: "400",
                        lineHeight: "2rem",
                      }}
                    >
                      <Link
                        className="dropdown-item "
                        to="manufacturing"
                        style={{ color: "#344b87" }}
                      >
                        Manufacturing
                      </Link>
                    </li>
                    <li
                      className="container"
                      style={{
                        fontFamily: "Louis George Cafe",
                        fontSize: "1rem",
                        // fontWeight: "400",
                        lineHeight: "2rem",
                      }}
                    >
                      <Link
                        className="dropdown-item "
                        to="wholeSaleDistribution"
                        style={{ color: "#344b87" }}
                      >
                        Wholesale & Distribution
                      </Link>
                    </li>
                    <li
                      className="container"
                      style={{
                        fontFamily: "Louis George Cafe",
                        fontSize: "1rem",
                        // fontWeight: "400",
                        lineHeight: "2rem",
                      }}
                    >
                      <Link
                        className="dropdown-item "
                        to="project-accounting"
                        style={{ color: "#344b87" }}
                      >
                        Project Accounting
                      </Link>
                    </li>
                    <li
                      className="container"
                      style={{
                        fontFamily: "Louis George Cafe",
                        fontSize: "1rem",
                        // fontWeight: "400",
                        lineHeight: "2rem",
                      }}
                    >
                      <Link
                        className="dropdown-item "
                        to="nonProfitOrganisation"
                        style={{ color: "#344b87" }}
                      >
                        Non-Profit Organizations
                      </Link>
                    </li>
                    <li
                      className="container"
                      style={{
                        fontFamily: "Louis George Cafe",
                        fontSize: "1rem",
                        // fontWeight: "400",
                        lineHeight: "2rem",
                      }}
                    >
                      <Link
                        className="dropdown-item "
                        to="foodAndBeverage"
                        style={{ color: "#344b87" }}
                      >
                        Food & Beverage
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link mx-4 px-3"
                    to="what-we-do"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      backgroundColor: "#344b87",
                      fontFamily: "Louis George Cafe",
                      borderRadius: 4,
                    }}
                  >
                    Services
                  </Link>
                </li>
                <li
                  className="nav-item p-0"
                  style={{
                    // color: "white",
                    // backgroundColor: "#344b87",
                    borderRadius: 4,
                    border: "none",
                  }}
                >
                  <Link
                    className="nav-link px-3"
                    to="about"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      backgroundColor: "#344b87",
                      fontFamily: "Louis George Cafe",
                      borderRadius: 4,
                    }}
                  >
                    Facts About Us
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="nav-link mx-4 px-3"
                    to="testimonial"
                    style={{
                      color: "white",
                      backgroundColor: "#344b87",
                      borderRadius: 4,
                    }}
                  >
                    Careers
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    className="nav-link mx-4 px-3"
                    to="get-in-touch"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      backgroundColor: "#344b87",
                      fontFamily: "Louis George Cafe",
                      borderRadius: 4,
                    }}
                  >
                    Touchbase
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
