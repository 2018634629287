import { Box, Button, Grid, Modal, Paper, TextField, } from '@mui/material'
import React, { useState } from 'react'
import Img from "../assets/contact/ContactImg.png";
import CallImg from "../assets/contact/Call.png";
import LocationImg from "../assets/contact/Location.png";
import MsjImg from "../assets/contact/Msj.png";


const ContactUsModal = ({ show, handleClose }) => {
    const [value, setValue] = useState('')

    return (
        <Modal open={show} onClose={handleClose}>


            <Grid container sx={{ justifyContent: 'center', }}>
                <Grid item xs={12} md={6} lg={8}>
                    <Paper sx={{ p: 4, mt: 10 }}>
                        <Box component='form'>
                            <Grid container spacing={2}>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                    <TextField
                                        label="First Name"
                                        variant="outlined"
                                        placeholder="Firstname"
                                        fullWidth
                                        // size='small'
                                        type="text"
                                        sx={{ borderColor: 'white', "& fieldset": { border: 'none' }, backgroundColor: 'rgba(145, 158, 171, 0.13)', py: 0.5, mx: 1, }}
                                    />
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                    <TextField
                                        label="Last Name"
                                        variant="outlined"
                                        placeholder="Lastname"
                                        fullWidth
                                        // size='small'
                                        type="text"
                                        sx={{ borderColor: 'white', "& fieldset": { border: 'none' }, backgroundColor: 'rgba(145, 158, 171, 0.13)', py: 0.5, mx: 1, }}
                                    />
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        placeholder="work-email"
                                        fullWidth
                                        // size='small'
                                        type="email"
                                        sx={{ borderColor: 'white', "& fieldset": { border: 'none' }, backgroundColor: 'rgba(145, 158, 171, 0.13)', py: 0.5, mx: 1, }}
                                    />
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                    <TextField
                                        label="Phone Number"
                                        variant="outlined"
                                        placeholder="xxx-xxx-xxxx"
                                        fullWidth
                                        // size='small'
                                        type="text"
                                        sx={{ borderColor: 'white', "& fieldset": { border: 'none' }, backgroundColor: 'rgba(145, 158, 171, 0.13)', py: 0.5, mx: 1, }}
                                    />
                                </Grid>
                                <Grid item container xl={12} lg={12} md={12} xs={12} sx={{ justifyContent: 'space-between' }} >
                                    <Button variant="contained" sx={{ borderRadius: '10px', py: 2, px: 7, backgroundColor: "#344B87", boxShadow: 5, my: { xs: 5, md: 0 } }} onClick={handleClose}>Close</Button>

                                    <Button type="submit" variant="contained" sx={{ borderRadius: '10px', py: 2, px: 7, backgroundColor: "#344B87", boxShadow: 5, my: { xs: 5, md: 0 } }}>Submit</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default ContactUsModal;