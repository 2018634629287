import React from 'react'
import Hero from '../assets/testimonials/Hero.png'
import Swiggy from '../assets/testimonials/Swiggy.png'
import Client1 from '../assets/testimonials/Client1.png'
import Client2 from '../assets/testimonials/Client2.png'
import Client3 from '../assets/testimonials/Client3.png'
import Client4 from '../assets/testimonials/Client4.png'

const Testimonials = () => {
    return (
        <>
            <div>

                <div className='container'>
                    <div className="row pt-5 pb-5">
                        <div className="col-lg-6 text-start pt-5">
                            <p className='mb-0' style={{ color: '#9ED2C4', fontWeight: 700, fontSize: '16px' }}>Listen from our clients </p>
                            <h1 className='pb-1 mb-0' style={{ color: '#344B87', fontSize: '35px', fontWeight: '600', lineHeight: '50px' }}>“We honestly would not be where we are today. Thanks to <spam style={{ color: '#9ED2C4' }}>SuiteCast</spam>”</h1>
                            <p className='mt-0 mb-0' style={{ fontWeight: 600 }}>-Leena Roy</p>
                            <p>Managing Director</p>
                            <img className='img-fluid' src={Swiggy} alt="Swiggy img logo" />
                        </div>
                        <div className="col-lg-6">
                            <div className='d-flex justify-content-lg-end justify-content-sm-center pe-3'>
                                <img className='pb-5 img-fluid' src={Hero} alt="Gruop Image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-column p-3' style={{ backgroundColor: 'rgba(158, 210, 196, 0.5)' }}>
                    <h1 className='text-center' style={{ color: '#344B87', fontSize: '36px', fontWeight: '700' }}>Don’t just take our word for it!</h1>
                    <div className="d-lg-flex d-md-flex justify-content-around">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <p className='p-0' style={{ color: '#344B87', fontSize: '5rem', fontWeight: '800' }}>106+</p>
                            <p className='p-0' style={{ color: '#344B87', fontSize: '16px', fontWeight: 500, marginTop: '-25px' }}>Projects</p>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <p className='' style={{ color: '#344B87', fontSize: '5rem', fontWeight: '800' }}>30+</p>
                            <p className='p-0' style={{ color: '#344B87', fontSize: '16px', fontWeight: 500, marginTop: '-25px' }}>Clients</p>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <p className='' style={{ color: '#344B87', fontSize: '5rem', fontWeight: '800' }}>10+</p>
                            <p className='p-0' style={{ color: '#344B87', fontSize: '16px', fontWeight: 500, marginTop: '-25px' }}>Expert Team</p>
                        </div>
                    </div>
                </div>


                <div className='container pt-5 pb-5'>
                    <h1 className='text-center pb-3 pt-3' style={{ color: '#344B87', fontSize: '36px', fontWeight: 700 }}>More from clients </h1>
                    <div className="row">
                        <div className="col-md-8">
                            <div className='p-2 d-flex justify-content-start'>
                                <div className="card p-2" style={{ backgroundColor: '#FFFFFF', }}>
                                    <div className='d-flex gap-5 align-items-center'>
                                        <img className='card-img' style={{ height: '7rem', width: '7rem' }} src={Client1} alt="Client image" />
                                        <div className='card-title pt-2'>
                                            <p className='pb-0' style={{ fontSize: '14px' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            <p className=''><span style={{ fontWeight: '600' }}>Carel D</span><br /> CEO - KPS Technologies</p>
                                            <p className='pt-0'></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4"></div>
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-8">
                            <div className='p-2 d-flex justify-content-end'>
                                <div className="card p-2" style={{ backgroundColor: '#FFFFFF', }}>
                                    <div className='d-flex gap-5 align-items-center'>
                                        <img className='card-img' style={{ height: '7rem', width: '7rem' }} src={Client2} alt="Client image" />
                                        <div className='card-title pt-2'>
                                            <p className='pb-0' style={{ fontSize: '14px' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            <p className=''><span style={{ fontWeight: '600' }}>Carel D</span><br /> CEO - KPS Technologies</p>
                                            <p className='pt-0'></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8"><div className='p-2 d-flex justify-content-start'>
                            <div className="card p-2" style={{ backgroundColor: '#FFFFFF', }}>
                                <div className='d-flex gap-5 align-items-center'>
                                    <img className='card-img' style={{ height: '7rem', width: '7rem' }} src={Client3} alt="Client image" />
                                    <div className='card-title pt-2'>
                                        <p className='pb-0' style={{ fontSize: '14px' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        <p className=''><span style={{ fontWeight: '600' }}>Carel D</span><br /> CEO - KPS Technologies</p>
                                        <p className='pt-0'></p>
                                    </div>
                                </div>
                            </div>
                        </div></div>
                        <div className="col-md-4"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-8"> <div className='p-2 d-flex justify-content-end'>
                            <div className="card p-2" style={{ backgroundColor: '#FFFFFF',}}>
                                <div className='d-flex gap-5 align-items-center'>
                                    <img className='card-img' style={{ height: '7rem', width: '7rem' }} src={Client4} alt="Client image" />
                                    <div className='card-title pt-2'>
                                        <p className='pb-0' style={{ fontSize: '14px' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        <p className=''><span style={{ fontWeight: '600' }}>Carel D</span><br /> CEO - KPS Technologies</p>
                                        <p className='pt-0'></p>
                                    </div>
                                </div>
                            </div>
                        </div> </div>
                    </div>
                    {/* <div className='p-2 d-flex justify-content-start'>
                        <div className="card p-2" style={{ backgroundColor: '#FFFFFF', width: '43rem', height: '11rem' }}>
                            <div className='d-flex gap-5 align-items-center'>
                                <img className='card-img' style={{ height: '7rem', width: '7rem' }} src={Client3} alt="Client image" />
                                <div className='card-title pt-2'>
                                    <p className='pb-0' style={{ fontSize: '14px' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <p className=''><span style={{ fontWeight: '600' }}>Carel D</span><br /> CEO - KPS Technologies</p>
                                    <p className='pt-0'></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='p-2 d-flex justify-content-end'>
                        <div className="card p-2" style={{ backgroundColor: '#FFFFFF', width:'43rem', height:'11rem' }}>
                            <div className='d-flex gap-5 align-items-center'>
                                <img className='card-img' style={{ height: '7rem', width: '7rem' }} src={Client4} alt="Client image" />
                                <div className='card-title pt-2'>
                                    <p className='pb-0' style={{ fontSize: '14px' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <p className=''><span style={{ fontWeight: '600' }}>Carel D</span><br /> CEO - KPS Technologies</p>
                                    <p className='pt-0'></p>
                                </div>
                            </div>
                        </div>
                    </div> */}


                </div>


            </div>
        </>
    )
}

export default Testimonials