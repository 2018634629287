import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

const data = [
  "Strategy and Planning",
  "Implementation",
  "Data Migration",
  "Integration",
  "Optimization",
  "Training",
];

const WhatWeKnownFor = () => {
  const [buttonPressed, setButtonPressed] = useState();

  const ButtonPressed = (index) => {
    setButtonPressed(index);
    // console.log(index);
  };

  return (
    <Grid sx={{ backgroundColor: "#344B87" }}>
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Grid container lg={12} md={12} xs={12}>
          <Grid item lg={4} md={4} xs={12} sx={{ margin: { xs: 5 } }}>
            <Typography
              variant="h3"
              sx={{
                color: "white",
                fontWeight: "700",
                my: "2rem",
                fontFamily: "Gogh Extra",
              }}
            >
              Speculating about NetSuite Implementation & Services !!!
            </Typography>

            <Typography
              variant="body2"
              sx={{
                color: "#919EAB",
                width: "70%",
                fontFamily: "Louis George Cafe",
                fontSize: "1rem",
              }}
            >
              Remodeling ERP to meet Industries’ Perfection SUITECast wizards
              are here to talk about remodelling and re-organizing your next ERP
              Goal
            </Typography>
            <Button
              variant="contained"
              sx={{
                my: "2.5rem",
                backgroundColor: "#cee8e1",
                p: 2,
                fontSize: "15px",
                fontWeight: 600,
              }}
            >
              <Link
                href="#"
                sx={{
                  color: "black",
                  textDecoration: "none",
                  fontFamily: "Louis George Cafe",
                }}
              >
                Explore more
              </Link>
            </Button>
          </Grid>
          <Grid
            container
            spacing={2}
            item
            lg={7}
            md={7}
            xs={12}
            className="buttons-item"
          >
            {data.map((item, index) => (
              <Grid item key={index} lg={6} md={6} xs={12}>
                <Box
                  sx={{
                    borderRadius: 7,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "rgba(145, 158, 171, 0.24)",
                    // px: 5,
                    height: "104px",
                    // pt: 3,
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor:
                      buttonPressed === index ? "#cee8e1" : "transparent",
                  }}
                  onClick={() => ButtonPressed(index)}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 600,
                      color: buttonPressed === index ? "black" : "white",
                      fontFamily: "Louis George Cafe",
                    }}
                  >
                    {item}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default WhatWeKnownFor;
