import React, { useEffect, useState } from "react";
import Abt from "../assets/aboutus/team-workk.png";
import Story from "../components/Story";
import OurTeam from "../components/home/OurTeam";
import whatWeDo from "../assets/aboutus/whatWeDo.png";
import Count from "../components/count/Count";
import ClientCount from "../components/count/ClientCount";
import Team from "../components/count/Team";
import Corousel from "./Corousel";
import ContactUsModal from "../components/ContactUsModal";
import { MenuItem } from "@mui/material";
import Demo from "../assets/whatWeDo/demo.png";

const About = () => {
  const [showModal, setshowModal] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div className="container">
        <ContactUsModal
          show={showModal}
          handleClose={() => setshowModal(false)}
        />
        <div className="row py-md-4">
          <div className="col-lg-7 text-start">
            <p
              className="m-0 py-4 p-tag-title"
              style={{ color: "#9ED2C4", fontWeight: 800, fontSize: "2rem" }}
            >
              About us
            </p>
            <h1
              className="pb-1 mt-1 page-title"
              style={{
                color: "#344B87",
                // color: "#ffffff",
                fontWeight: "500",
                fontFamily: "Gogh Extra",
                fontSize: "2.5rem",
              }}
            >
              LAYING A FOUNDATION TODAY TO NURTURE YOUR DIGITAL PLATFORM
              TOMORROW
            </h1>
            <p
              className="px-1"
              style={{ fontFamily: "Louis George Cafe", fontSize: "1.2rem" }}
            >
              Our NetSuite’s consulting and strategical services are always
              specific to client’s perspective Which results in what we need :
              CUSTOMER SUCCESS
            </p>
          </div>
          <div className="col-lg-5">
            <div className="d-flex justify-content-lg-end justify-content-sm-center pe-3">
              <img className="pb-5 img-fluid" src={Abt} alt="Gruop Image" />
            </div>
          </div>
        </div>
      </div>
      <div
        className=""
        style={{ backgroundColor: "#344B87" }}
        // data-aos="zoom-in"
        // data-aos-duration="1500"
      >
        <div className="container">
          <Corousel />
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${whatWeDo})`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="p-md-4"
      >
        <div className="container py-md-5 py-4">
          <h2
            className=""
            style={{
              color: "#ffffff",
              fontWeight: "500",
              fontFamily: "Gogh Extra",
              fontSize: "2.7rem",
            }}
          >
            We embrace Distinctiveness, Ownership, Perseverance & Involvement.
          </h2>
          <p
            className="my-3 mx-1"
            style={{
              color: "white",
              fontStyle: "italic",
              fontSize: "1.4rem",
              fontFamily: "Louis George Cafe",
            }}
          >
            "SuiteCast creates a workplace that nurtures and promotes strategies
            that make us the perfect architects to design what the customer’s
            solution."
          </p>
          <p
            className="my-md-5 mx-1"
            style={{
              color: "white",
              fontStyle: "normal",
              fontSize: "1.3rem",
              fontFamily: "Louis George Cafe",
            }}
          >
            
          </p>
        </div>
      </div>
      {/* <Story /> */}

      <OurTeam />
      <div
        className="d-flex flex-column p-3"
        style={{ backgroundColor: "rgba(158, 210, 196, 0.5)" }}
      >
        <h1
          className="text-center"
          style={{
            color: "#344B87",
            fontSize: "2.5rem",
            fontWeight: "700",
            fontFamily: "Gogh Extra",
          }}
        >
          Don’t just take our word for it!
        </h1>
        <div className="d-lg-flex d-md-flex justify-content-around">
          <div className="d-flex flex-column justify-content-center align-items-center">
            {/* <p className='p-0' style={{ color: '#344B87', fontSize: '5rem', fontWeight: '800' }}>106+</p> */}
            <Count />
            <p
              className="p-0"
              style={{
                color: "#344B87",
                fontSize: "1.2rem",
                fontWeight: 500,
                marginTop: "-25px",
                fontFamily: "Louis George Cafe",
              }}
            >
              Projects
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {/* <p className='' style={{ color: '#344B87', fontSize: '5rem', fontWeight: '800' }}>30+</p> */}
            <ClientCount />
            <p
              className="p-0"
              style={{
                color: "#344B87",
                fontSize: "1.2rem",
                fontWeight: 500,
                marginTop: "-25px",
                fontFamily: "Louis George Cafe",
              }}
            >
              Clients
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {/* <p className='' style={{ color: '#344B87', fontSize: '5rem', fontWeight: '800' }}>10+</p> */}
            <Team />
            <p
              className="p-0"
              style={{
                color: "#344B87",
                fontSize: "1.2rem",
                fontWeight: 500,
                // marginTop: "-25px",
                fontFamily:'Louis George Cafe',
              }}
            >
              Expert Team
            </p>
          </div>
        </div>
      </div>
      <div className="container my-md-5 my-4">
        <div
          style={{
            backgroundImage: `url(${Demo})`,
            backgroundRepeat: "no-repeat",
          }}
          className="p-md-5 d-flex justify-content-center"
        >
          <MenuItem onClick={() => setshowModal(true)}>
            <div className="d-flex justify-content-center">
              <div className="d-flex justify-content-center">
                <button
                  style={{
                    backgroundColor: "white",
                    color: "#344B87",
                    fontWeight: "600",
                    fontSize: "1.2em",
                    border: 0,
                    borderRadius: 2,
                  }}
                  className="px-sm-5 py-3 mx-sm-5 "
                >
                  Request a Demo or More Information
                </button>
              </div>
            </div>
          </MenuItem>
        </div>
      </div>
    </div>
  );
};

export default About;
