import React from "react";
import SuiteCast from "../assets/footer/footerSuitCast.png";
import Facebook from "../assets/footer/facebook.png";
import Insta from "../assets/footer/instagram.png";
import YouTube from "../assets/footer/videosquare.png";
import Oracle from "../assets/footer/oracleFooter.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* <div className="pt-5" style={{ backgroundColor: "#344B87" }}>
        <div className="container">
          <div className="row p-5">
            <div className="col-lg-2z">
              <div className="">
                <img src={SuiteCast} alt="SutieCastLogo" />
                <div className="d-flex gap-2 pt-3">
                  <img src={Facebook} alt="facebook Img" />
                  <img src={Insta} alt="Insta Img" />
                  <img src={YouTube} alt="YouTube Img" />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="d-flex flex-column text-light">
                <p>About us</p>
                <p>Privacy policy</p>
                <p>Contact us</p>
              </div>
            </div>
            <div className="col-lg-2">
              <img src={Oracle} alt="Oracle Img" />
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-column ms-5">
                <p style={{ color: "white", fontWeight: 700 }}>
                  Our Newsletter
                </p>
                <form className="d-flex" role="search">
                  <input
                    className="form-control rounded-0"
                    type="search"
                    placeholder="Enter your e-mail here..."
                    aria-label="Search"
                  />
                  <button
                    className="btn rounded-0 text-light"
                    style={{ backgroundColor: "#9ed2c4" }}
                    type="submit"
                  >
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="p-md-5 p-3" style={{ backgroundColor: "#344B87" }}>
        <div className="container">
          <div>
            <div className="d-flex flex-wrap justify-content-around align-items-center">
              <div className="">
                <img src={SuiteCast} className="pe-5" alt="SutieCastLogo" />
                <div className="d-flex gap-2 pt-3">
                  <img src={Facebook} alt="facebook Img" />
                  <img src={Insta} alt="Insta Img" />
                  <img src={YouTube} alt="YouTube Img" />
                </div>
              </div>
              <div className="d-flex flex-column text-light">
                <p
                  style={{
                    fontFamily: "Gogh Extra",
                  }}
                >
                     <Link
                    to="about"
                    style={{
                      color: "#ffffff",
                      textDecoration: "none",
                      // color: "rgba(~17, 17, 17, 0.5)",
                      fontWeight: "400",
                      fontFamily: "Gogh Extra",
                    }}
                  >
                  About us
                  </Link>
                </p>
                <p
                  style={{
                    fontFamily: "Gogh Extra",
                  }}
                >
                  Privacy policy
                </p>
                <p
                  style={{
                    fontFamily: "Gogh Extra",
                  }}
                >
                  <Link
                    to="get-in-touch"
                    style={{
                      color: "#ffffff",
                      textDecoration: "none",
                      // color: "rgba(17, 17, 17, 0.5)",
                      fontWeight: "400",
                      fontFamily: "Gogh Extra",
                    }}
                  >
                    Touchbase
                  </Link>
                </p>
              </div>
              <div className="d-flex flex-column ms-md-5 mt-2">
                <p className="mb-1" style={{ color: "white", fontWeight: 700 }}>
                  Our Newsletter
                </p>
                <form className="d-flex" role="search">
                  <input
                    className="form-control rounded-0"
                    type="search"
                    style={{
                      fontFamily: "Louis George Cafe",
                    }}
                    placeholder="Enter your e-mail here..."
                    aria-label="Search"
                  />
                  <button
                    className="btn rounded-0 text-light"
                    style={{
                      backgroundColor: "#9ed2c4",
                      fontFamily: "Gogh Extra",
                      color: "black",
                    }}
                    type="submit"
                  >
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
