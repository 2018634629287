import React, { useState } from "react";
import Hero from "../assets/whatWeDo/services-real-time-removebg-preview.png";
import Consultant from "../assets/whatWeDo/consultant.png";
import ConsultantTeam from "../assets/whatWeDo/consutant-team.png";
import ConsultantStrategy from "../assets/whatWeDo/consultant-strategy.png";
import Demo from "../assets/whatWeDo/demo.png";
import Transformation from "../assets/whatWeDo/transformation.png";
import Industries from "../components/home/Industries";
import { Button, Divider, Grid, MenuItem, Typography } from "@mui/material";
import ContactUsModal from "../components/ContactUsModal";

const WhatWeDo = () => {
  const [showModal, setshowModal] = useState(false);

  return (
    <>
      <div className="">
        <ContactUsModal
          show={showModal}
          handleClose={() => setshowModal(false)}
        />
        <div className="container pt-3 pb-3">
          <div className="row pt-md-5 ">
            <div className="col-lg-6 text-start">
              <p
                className="mb-0 p-tag-title"
                style={{
                  color: "#9ED2C4",
                  fontWeight: 800,
                  fontSize: "2.2rem",
                  fontFamily: "Louis George Cafe",
                }}
              >
                What we do?
              </p>
              <h1
                className="pb-1 page-title"
                style={{
                  color: "#344B87",
                  fontSize: "3.2rem",
                  fontFamily: "Gogh Extra",
                }}
              >
                NETSUITE Services{" "}
              </h1>
              <p
                className="w-75 m-w-100 px-1 py-3"
                style={{ fontSize: "1.2rem", fontFamily: "Louis George Cafe" }}
              >
                NetSuite ERP is an all-in-one cloud business management solution
                that helps organizations operate more effectively by automating
                core processes and providing real-time visibility into
                operational and financial performance. With a single, integrated
                suite of applications for managing accounting, order processing,
                inventory management, production, supply chain and warehouse
                operations, NetSuite ERP gives companies clear visibility into
                their data and tighter control over their businesses.
              </p>
            </div>

            <div className="col-lg-6 text-center">
              <img className="img-fluid pt-0" src={Hero} alt="Image" />
            </div>
          </div>
        </div>

        {/* <Industries /> */}
        <div
          style={{
            backgroundImage: `url(${Transformation})`,
          }}
          className="p-md-5"
        >
          <div className="container py-4 py-md-5">
            <h2
              className=""
              style={{
                color: "white",
              
                fontFamily: "Louis George Cafe",
                fontSize: "2.7rem",
              }}
            >
              Transformations are <b>Tough</b> & the digital ones are <b>Tougher</b>
            </h2>
            <p
              className="my-3 mx-1 "
              style={{
                color: "white",
                fontStyle: "italic",
                fontSize: "1.6rem",
                fontFamily: "Louis George Cafe",
              }}
            >
              "WE BELIEVE IN PUTTING YOUR PROCESS, OPERATION & GOALS ON THE
              SUMMIT."
            </p>
            <p
              className="my-md-5 mx-1"
              style={{
                color: "white",
                fontStyle: "normal",
                fontFamily: "Louis George Cafe",
                fontSize: "1.3rem",
              }}
            >
              The key to a successful digital transformation project is creating
              a masterplan that incorporates your people, processes, and
              technology. Our technology strategical services fences in the
              implementation methodology, change management, and user adoption,
              and we put in enormous efforts and hours to build accurate
              solutions for your business.
            </p>
          </div>
        </div>

        <div className="">
          {/* <div className="container pb-5 ">
            <div className="row">
              <div className="col-lg-3">
                <div
                  className="p-4"
                  style={{
                    backgroundColor: "rgba(158, 210, 196, 0.5)",
                    height: "100%",
                  }}
                >
                  <div className="d-flex flex-row align-items-start">
                    <img className="p-0" src={VectorOne} alt="Card image cap" />
                    <p
                      className="ms-3"
                      style={{
                        color: "#344B87",
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      Strategy and Advisory
                    </p>
                  </div>

                  <div className="mt-2">
                    <p className="text-dark" style={{ fontSize: "12px" }}>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div
                  className="p-4"
                  style={{
                    backgroundColor: "rgba(158, 210, 196, 0.5)",
                    height: "100%",
                  }}
                >
                  <div className="d-flex flex-row align-items-start">
                    <img
                      className="p-0"
                      src={VectorThree}
                      alt="Card image cap"
                    />
                    <p
                      className="ms-3"
                      style={{
                        color: "#344B87",
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      Implementation and optimization
                    </p>
                  </div>

                  <div className="mt-0">
                    <p className="text-dark" style={{ fontSize: "12px" }}>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div
                  className="p-4"
                  style={{
                    backgroundColor: "rgba(158, 210, 196, 0.5)",
                    height: "100%",
                  }}
                >
                  <div className="d-flex flex-row align-items-start">
                    <img className="p-0" src={VectorTwo} alt="Card image cap" />
                    <p
                      className="ms-3"
                      style={{
                        color: "#344B87",
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      Ongoing Innovation
                    </p>
                  </div>

                  <div className="mt-3">
                    <p className="text-dark" style={{ fontSize: "12px" }}>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div
                  className="p-4"
                  style={{
                    backgroundColor: "rgba(158, 210, 196, 0.5)",
                    height: "100%",
                  }}
                >
                  <div className="d-flex flex-row align-items-start">
                    <img
                      className="p-0"
                      src={VectorFour}
                      alt="Card image cap"
                    />
                    <p
                      className="ms-3"
                      style={{
                        color: "#344B87",
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      Industry specific CRM & ERP
                    </p>
                  </div>

                  <div className="mt-0">
                    <p className="text-dark" style={{ fontSize: "12px" }}>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <OurPartner />
          </div> */}

          <div className="p-4 pt-md-5 d-flex flex-column justify-content-center align-items-center">
            <h1
              className="page-title"
              style={{
                color: "#344B87",
                fontWeight: "700",
                fontSize: "3.2rem",
                fontFamily: "Gogh Extra",
              }}
            >
              Services
            </h1>
          </div>
          {/* consultant-services */}
          <div>
            <Grid container spacing={0}>
              <Grid item md={6} xs={12}>
                <img className="img-fluid" src={Consultant} alt="Image" />
              </Grid>
              <Grid item md={6} xs={12} className="px-4">
                <h3
                  className="pb-1 mt-4 mt-md-1"
                  style={{
                    color: "#344B87",
                    fontWeight: "500",
                    fontFamily: "Gogh Extra",
                    fontSize: "2rem",
                  }}
                >
                  Consulting and Implementation
                </h3>
                <hr className="height-auto"
                  style={{ width: "90%", color: "#344B87", height: "20px" }}
                ></hr>
                <p
                  className="w-75 m-w-100 px-1 py-3 mb-0"
                  style={{
                    fontFamily: "Louis George Cafe",
                    fontSize: "1.2rem",
                  }}
                >
                  Our NetSuite wizards construct the Oracle NetSuite
                  implementation blueprint by aligning and developing plan which
                  aligns to industry’s standards to deliver on-time, on-budget
                  implementation
                </p>
              </Grid>

              <Grid item md={6} xs={12} className="px-4 py-3">
                <h3
                   className="pb-1 mt-4 mt-md-1"
                  style={{
                    color: "#344B87",
                    fontWeight: "500",
                    fontFamily: "Gogh Extra",
                    fontSize: "2rem",
                  }}
                >
                  Managed Services
                </h3>
                <hr className="height-auto"
                  style={{ width: "90%", color: "#344B87", height: "20px" }}
                ></hr>
                <p
                  className="w-75 m-w-100 px-1 pt-3 mb-0"
                  style={{
                    fontFamily: "Louis George Cafe",
                    fontSize: "1.2rem",
                  }}
                >
                  With the continuous change in market conditions, is essential
                  need of Process improvements, productivity adrenaline while
                  dealing with multi Level complexities. Our Managed Services
                  team helps our customers to take a proactive approach To
                  maintenance rather than waiting for the breakdown.
                </p>
                <p
                  className="w-75 m-w-100 px-1"
                  style={{
                    fontFamily: "Louis George Cafe",
                    fontSize: "1.2rem",
                  }}
                >
                  Our Managed Services team helps our customers to take a
                  proactive approach To maintenance rather than waiting for the
                  breakdown.
                </p>
              </Grid>
              <Grid item md={6} xs={12}>
                <img className="img-fluid" src={ConsultantTeam} alt="Image" />
              </Grid>

              <Grid item md={6} xs={12}>
                <img
                  className="img-fluid"
                  src={ConsultantStrategy}
                  alt="Image"
                />
              </Grid>
              <Grid item md={6} xs={12} className="px-4 py-3">
                <h3
                className="pb-1 mt-4 mt-md-1"
                  style={{
                    color: "#344B87",
                    fontWeight: "500",
                    fontFamily: "Gogh Extra",
                    fontSize: "2rem",
                  }}
                >
                  Strategical Services
                </h3>
                <hr className="height-auto"
                  style={{ width: "90%", color: "#344B87", height: "20px" }}
                ></hr>
                <p
                  className="w-75 m-w-100 px-1 pt-3 mb-0"
                  style={{
                    fontFamily: "Louis George Cafe",
                    fontSize: "1.2rem",
                  }}
                >
                  We will connect our wizards starting at the executive level in
                  order to ensure we build your vision around the business
                  imperatives most impactful to your company. From there, our
                  experts will guide your implementation and optimization goals
                  based on our best practices and informed by your business
                  goals.
                </p>
              </Grid>
            </Grid>
            {/* <div
              style={{
                backgroundImage: `url(${Transformation})`,
              }}
              className="p-5"
            >
              <div
                  className="container py-5"
              
              >
                <h2
                  className=""
                  style={{ color: "#ffffff", fontWeight: "500" }}
                >
                  Transformations are tough & the digital ones are Tougher
                </h2>
                <p
                  className="my-3 mx-1"
                  style={{
                    color: "white",
                    fontStyle: "italic",
                    fontSize: "0.9rem",
                  }}
                >
                  "WE BELIEVE IN PUTTING YOUR PROCESS, OPERATION & GOALS ON THE
                  SUMMIT."
                </p>
                <p
                  className="my-5 mx-1"
                  style={{
                    color: "white",
                    fontStyle: "normal",
                  }}
                >
                  The key to a successful digital transformation project is
                  creating a masterplan that incorporates your people,
                  processes, and technology. Our technology strategical services
                  fences in the implementation methodology, change management,
                  and user adoption, and we put in enormous efforts and hours to
                  build accurate solutions for your business.
                </p>
              </div>
            </div> */}
            <Industries />
          </div>
          <div className="container my-md-5 my-4">
            <div
              style={{
                backgroundImage: `url(${Demo})`,
                backgroundRepeat: "no-repeat",
              }}
              className="p-md-5 d-flex justify-content-center"
            >
              <MenuItem onClick={() => setshowModal(true)}>
                <div className="d-flex justify-content-center">
                  <div className="d-flex justify-content-center">
                    <button
                      style={{
                        backgroundColor: "white",
                        color: "#344B87",
                        fontWeight: "600",
                        fontSize: "1.2em",
                        border: 0,
                        borderRadius: 2,
                      }}
                      className="px-sm-5 py-3 mx-sm-5 "
                    >
                      Request a Demo or More Information
                    </button>
                  </div>
                </div>
              </MenuItem>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatWeDo;
