import React from 'react'
import financialImg from '../../assets/logo/financialServices.png'
import consumerImg from '../../assets/logo/consumer.png'
import nonProfitImg from '../../assets/logo/nonProfit.png'
import technologyImg from '../../assets/logo/technology.png'

const Industries = () => {
  return (
    <div className='text-center pt-4 pt-md-5'>
          <h2 className='pb-2 page-title' style={{ color: '#344B87', fontWeight: '700', fontFamily:'Gogh Extra', fontSize:'3.2rem' }}>Industries we serve</h2>
          
          <div className='d-flex justify-content-around p-5' style={{backgroundColor:'rgba(158, 210, 196, 0.5)'}}>
            <div><img className='img-fluid w-75 css3-notification' data-aos="fade-down" data-aos-duration="3000" src={financialImg} alt="financialImg " /></div>
            <div><img className='img-fluid w-75 css3-notification' data-aos="fade-down" data-aos-duration="3000" src={consumerImg} alt="consumerImg " /></div>
            <div><img className='img-fluid w-75 css3-notification' data-aos="fade-down" data-aos-duration="3000" src={nonProfitImg} alt="nonProfitImg " /></div>
            <div><img className='img-fluid w-75 css3-notification' data-aos="fade-down" data-aos-duration="3000" src={technologyImg} alt="technologyImg " /></div>
        </div>
    </div>
  )
}

export default Industries               