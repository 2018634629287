import React, { useEffect, useState } from "react";
import "./count.css";

const counts = {
  id: "001",
  label: "Countries we are spread in...",
  number: "+100",
  duration: "3",
};

const Team = () => {
  const [count, setCount] = useState("0");

  let { id, label, number, duration } = counts;
//   console.log("Count object is:", id, label, number, duration);

  useEffect(() => {
    let start = 100;
    // first three numbers from props
    const end = parseInt(number.substring(0, 3));
    // if zero, return
    if (start === end) return;

    // find duration per increment
    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 200;

    // timer increments start counter
    // then updates count
    // ends if start reaches end
    let timer = setInterval(() => {
      start -= 1;
      setCount(start + 1);
      console.log(String(start));
      if (start === 9) clearInterval(timer);
    }, incrementTime);

    // dependency array
  }, [number, duration]);

  return (
    <div className="countContainer" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
      {/* <h3 className="p-4 text-center" style={{ color: '#344B87', fontWeight: '700', fontFamily:'Gogh Extra', fontSize:'2rem', }}>{label}</h3> */}
      <p className="count"  style={{ color: '#344B87', fontSize: '5rem', fontWeight: '800' }}>+{count}</p>
    </div>
  );
};

export default Team;
