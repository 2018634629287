import React from "react";
import Card from "../../assets/home/card.png";
import { Box, Grid, Typography } from "@mui/material";

const OurTeam = () => {
  return (
    <div className="container p-md-5 py-3">
      <div className="p-0 d-flex flex-column justify-content-center align-items-center">
        <p
          className="mt-0 mb-0 py-2"
          style={{
            color: "#9ED2C4",
            fontWeight: 700,
            fontFamily: "Gogh Extra",
            fontSize: "2rem",
          }}
        >
          3Bs of Business
        </p>
        <h2
          className="mb-5 page-title"
          style={{
            color: "#344B87",
            fontWeight: "700",
            fontFamily: "Gogh Extra",
            fontSize: "2.5rem",
          }}
        >
          We strive hard to be aligned with the 3Bs of Business
        </h2>
      </div>

      {/* <div className='d-flex'>
                <div className="card" style="width: 18rem;">
                    { <img className="card-img-top" src="..." alt="Card image cap"> }
                        <div className="card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>
                </div>
            </div> */}

      {/* <div className='container pb-5'>
                <div className='d-flex flex-row justify-content-evenly'> */}
      {/* card1 */}
      {/* <div className='card border-0'>
                        <img src={Card} alt="card img" />
                        <div className=''>
                            <h6 className="card-title ">Name</h6>
                            <p className="card-text ">Designation</p>
                        </div>
                    </div> */}
      {/* <div className='p-5'>
                        <p  style={{backgroundColor:'black'}}>Balance</p>
                    </div> */}
      {/* card1 */}
      {/* <div className='card border-0'>
                        <img src={Card} alt="card img" />
                        <div className=''>
                            <h6 className="card-title ">Name</h6>
                            <p className="card-text ">Designation</p>
                        </div>
                    </div>
                    <div className='card border-0'>
                        <img src={Card} alt="card img" />
                        <div className=''>
                            <h6 className="card-title ">Name</h6>
                            <p className="card-text ">Designation</p>
                        </div>
                    </div>
                    <div className='card border-0'>
                        <img src={Card} alt="card img" />
                        <div className=''>
                            <h6 className="card-title ">Name</h6>
                            <p className="card-text ">Designation</p>
                        </div>
                    </div> */}
      {/* </div> */}
      {/* </div> */}
      <div className="team-btn-conatiner">
        <Grid
          container
          spacing={2}
          item
          lg={12}
          md={12}
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item lg={4} md={6} xs={12}>
            <Box
              sx={{
                borderRadius: 7,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "rgba(145, 158, 171, 0.24)",
                // px: 5,
                height: "25rem",
                // pt: 3,
                // textAlign: "center",
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // justifyContent: "center",
                backgroundColor: "#344B87",
                padding: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  color: "white",
                  fontFamily: "Gogh Extra",
                  textAlign: "center",
                  fontSize: "2rem",
                }}
              >
                Balance
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 400,
                  color: "white",
                  fontFamily: "Louis George Cafe",
                  marginTop: 2,
                  fontStyle: "italic",
                }}
              >
                "Maintaining Balance is one of the key points for a successful
                project implementation."
              </Typography>
              <Typography sx={{ color: "white", lineHeight: 2.5 }}>
                We at SUITECast make sure that
              </Typography>

              <ul style={{ marginTop: 12, color: "white" }}>
                <li style={{ lineHeight: 1.7 }}>
                  Extract The Problem, Then Present Solutions
                </li>
                <li style={{ lineHeight: 1.7 }}>
                  {" "}
                  Validate The Customer’s Feelings{" "}
                </li>
                <li style={{ lineHeight: 1.7 }}>
                  Get To The Bottom Of What The Client Really Wants
                </li>
                <li style={{ lineHeight: 1.7 }}>
                  {" "}
                  Be Transparent During The Conversation{" "}
                </li>
                <li style={{ lineHeight: 1.7 }}>
                  Keep In Constant Communication With Customers And Employees
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <Box
              sx={{
                borderRadius: 7,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "rgba(145, 158, 171, 0.24)",
                // px: 5,
                height: "25rem",
                // pt: 3,
                // textAlign: "center",
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // justifyContent: "center",
                backgroundColor: "#344B87",
                padding: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  color: "white",
                  fontFamily: "Gogh Extra",
                  textAlign: "center",
                  fontSize: "2rem",
                }}
              >
                Benchmark
              </Typography>
              <ul style={{ marginTop: 20 }}>
                <li
                  style={{
                    fontWeight: 400,
                    color: "white",
                    fontFamily: "Louis George Cafe",
                    marginTop: 2,
                  }}
                >
                  As business ethics have been evolving with time, the growth of
                  business relates with the satisfaction of people working in
                  one end and customer being satisfied equally at the other end.
                </li>
              </ul>
              <ul style={{ marginTop: 10 }}>
                <li
                  style={{
                    fontWeight: 400,
                    color: "white",
                    fontFamily: "Louis George Cafe",
                    marginTop: 2,
                  }}
                >
                  Hence we have business with excellent culture. Honesty and
                  integrity has only the potential to sustain the growth and
                  provide satisfaction to all internal and external customers.
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <Box
              sx={{
                borderRadius: 7,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "rgba(145, 158, 171, 0.24)",
                // px: 5,
                height: "25rem",
                // pt: 3,
                // textAlign: "center",
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // justifyContent: "center",
                backgroundColor: "#344B87",
                padding: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  color: "white",
                  fontFamily: "Gogh Extra",
                  textAlign: "center",
                  fontSize: "2rem",
                }}
              >
                Benevolence
              </Typography>
              <ul style={{ marginTop: 20 }}>
                <li
                  style={{
                    fontWeight: 400,
                    color: "white",
                    fontFamily: "Louis George Cafe",
                    marginTop: 2,
                  }}
                >
                  We provide true value with a commitment for services that do
                  good for the customers who purchase and use our services.
                </li>
              </ul>
              <ul style={{ marginTop: 10 }}>
                <li
                  style={{
                    fontWeight: 400,
                    color: "white",
                    fontFamily: "Louis George Cafe",
                    marginTop: 2,
                  }}
                >
                  We feel much for our customer’s need and keep it on Summit.
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OurTeam;
