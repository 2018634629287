import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";

const Services = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div>
        <h2
          className="ms-4 ps-1 mb-5 page-title"
          style={{ color: "#344B87", fontWeight: "700", fontFamily: "Gogh Extra", fontSize:'3.2rem' }}
          data-aos="zoom-out"
        >
          Industry specific solutions
        </h2>
      </div>
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          elevation="0"
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            // expandIcon={<ControlPointIcon />}
            expandIcon={
              expanded === "panel1" ? (
                <RemoveCircleOutlineIcon style={{ fill: "#344b87" }} />
              ) : (
                <AddCircleSharpIcon style={{ fill: "#344b87" }} />
              )
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              color="#344b87"
              sx={{
                padding: "1rem",
                // flexShrink: 0,
                fontFamily: "Gogh Extra",
                fontWeight: 600,
                fontSize: "16px",
              }}
          
            >
              Financial Management, Planning & Budgeting
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "Louis George Cafe",
                fontSize: "1rem",
                color: "grey",
                marginRight: "1rem",
                marginLeft: "1rem",
              }}
            >
              Simplify your business and make data-driven decisions by
              consolidating financial and operational data across your
              organization, making your financial operations more efficient
              while helping you close your books with confidence. With Oracle
              NetSuite Planning and Budgeting, we perform both company-wide and
              departmental budgeting and financial planning with modeling
              capabilities, approval workflows, and reporting within one
              collaborative, scalable solution.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          elevation="0"
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel2" ? (
                <RemoveCircleOutlineIcon style={{ fill: "#344b87" }} />
              ) : (
                <AddCircleSharpIcon style={{ fill: "#344b87" }} />
              )
            }
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography
              color="#344b87"
              sx={{
                padding: "1rem",
                // flexShrink: 0,
                fontFamily: "Gogh Extra",
                fontWeight: 600,
                fontSize: "16px",
              }}
            
            >
              Item & Inventory Management
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "Louis George Cafe",
                fontSize: "1rem",
                color: "grey",
                marginRight: "1rem",
                marginLeft: "1rem",
              }}
            >
              Item & Inventory Management inventory levels by providing a
              real-time, company-wide view of inventory across all sales
              channels and locations, delivering a best-in-class experience by
              ensuring product availability across all customer touch points.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          elevation="0"
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel3" ? (
                <RemoveCircleOutlineIcon style={{ fill: "#344b87" }} />
              ) : (
                <AddCircleSharpIcon style={{ fill: "#344b87" }} />
              )
            }
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography
              color="#344b87"
              sx={{
                padding: "1rem",
                // flexShrink: 0,
                fontFamily: "Gogh Extra",
                fontWeight: 600,
                fontSize: "16px",
              }}
          
            >
              Advanced Order & Revenue Management
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "Louis George Cafe",
                fontSize: "1rem",
                color: "grey",
                marginRight: "1rem",
                marginLeft: "1rem",
              }}
            >
              With Oracle NetSuite Order Management, we help you automate and
              manage the life cycle of an order and simplify the complexities of
              evolving revenue recognition.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          elevation="0"
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel4" ? (
                <RemoveCircleOutlineIcon style={{ fill: "#344b87" }} />
              ) : (
                <AddCircleSharpIcon style={{ fill: "#344b87" }} />
              )
            }
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography
              color="#344b87"
              sx={{
                padding: "1rem",
                // flexShrink: 0,
                fontFamily: "Gogh Extra",
                fontWeight: 600,
                fontSize: "16px",
              }}
            
            >
              Contract Management & SuiteBilling
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "Louis George Cafe",
                fontSize: "1rem",
                color: "grey",
                marginRight: "1rem",
                marginLeft: "1rem",
              }}
            >
              We simplify your complex order-to-revenue process while providing
              you with unprecedented control and flexibility across your
              contract management and billing operations. We can help you easily
              manage renewals, upsells, downsells and improve the overall client
              experience to minimize attrition.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          elevation="0"
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel5" ? (
                <RemoveCircleOutlineIcon style={{ fill: "#344b87" }} />
              ) : (
                <AddCircleSharpIcon style={{ fill: "#344b87" }} />
              )
            }
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <Typography
              color="#344b87"
              sx={{
                padding: "1rem",
                // flexShrink: 0,
                fontFamily: "Gogh Extra",
                fontWeight: 600,
                fontSize: "16px",
              }}
          
            >
              Demand Planning & Supply Chain Management
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "Louis George Cafe",
                fontSize: "1rem",
                color: "grey",
                marginRight: "1rem",
                marginLeft: "1rem",
              }}
            >
              Unify sales, purchasing, supply chain and operations to minimize
              excess inventory and mitigate supply chain disruptions, increasing
              profitability while ensuring your customers are satisfied every
              step of the way.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          elevation="0"
          onChange={handleChange("panel6")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel6" ? (
                <RemoveCircleOutlineIcon style={{ fill: "#344b87" }} />
              ) : (
                <AddCircleSharpIcon style={{ fill: "#344b87" }} />
              )
            }
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            <Typography
              color="#344b87"
              sx={{
                padding: "1rem",
                // flexShrink: 0,
                fontFamily: "Gogh Extra",
                fontWeight: 600,
                fontSize: "16px",
              }}
            
            >
              Warehouse & Fulfillment
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "Louis George Cafe",
                fontSize: "1rem",
                color: "grey",
                marginRight: "1rem",
                marginLeft: "1rem",
              }}
            >
              Modernize your warehouse operations with best-in-class solutions
              to handle thousands of SKUs across however many locations you
              have. We’ll arm your team with mobile solutions to reduce manual
              processes and give them real-time inventory visibility – no matter
              where they are.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel7"}
          elevation="0"
          onChange={handleChange("panel7")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel7" ? (
                <RemoveCircleOutlineIcon style={{ fill: "#344b87" }} />
              ) : (
                <AddCircleSharpIcon style={{ fill: "#344b87" }} />
              )
            }
            aria-controls="panel7bh-content"
            id="panel7bh-header"
          >
            <Typography
              color="#344b87"
              sx={{
                padding: "1rem",
                // flexShrink: 0,
                fontFamily: "Gogh Extra",
                fontWeight: 600,
                fontSize: "16px",
              }}
          
            >
              SuiteProjects & Project Accounting
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "Louis George Cafe",
                fontSize: "1rem",
                color: "grey",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              Manage projects or installations by providing solutions to track
              project billing, tasks/milestones, work breakdown structure (WBS),
              resource allocation and skills, and more. This enables your
              project managers to mitigate risks and manage project expenses in
              one centralized location.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default Services;
